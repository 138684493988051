.checkbox-container {
  display: grid;
  .checkbox-field {
    line-height: 36px;
    margin: 10px 30px;
  }
}
.cb-group {
  margin-left: -20px;
}
.checkbox-container .p-divider.p-divider-horizontal {
  margin: 0.3rem 0 !important;
}
.p-column-filter-buttonbar {
  justify-content: space-evenly !important;
}
