.help-section {
    margin-top: 6rem;
}
    .help-heading {
        background-color: #052941;
        padding: 1rem;

        h3 {
            font-family: Poppins;
            font-size: 42px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
        }
    }

    .border-right-3 {
        border-color: rgba(217, 217, 217, 0.5);
    }
.help-sub-section{
        .category-section {
            h3 {
                font-family: Poppins;
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                text-align: left;
                color: #000000;
            }

            .sub-category {
                p {
                    display: flex;
                    font-family: Poppins;
                    font-size: 20px !important;
                    font-weight: 700 !important;
                    line-height: 30px !important;
                    text-align: left;
                    width: fit-content;
                    cursor: pointer;
                }
            }
            .sub-category-details {
                p {
                    display: flex;
                    font-family: Poppins;
                    font-size: 20px !important;
                    font-weight: 700 !important;
                    line-height: 30px !important;
                    text-align: left;
                    width: fit-content;
                    cursor: pointer;
                }
            }
        }



        .guides-section {
            .guides {
                .img {
                    svg {
                        width: 60px;
                        height: 85px;
                        color: #EA8254;
                    }
                }
            }

            .personal {
                .img {
                    .filepen {
                        width: 60px;
                        height: 85px;
                        color: #EBB448;
                    }

                    .atom {
                        width: 60px;
                        height: 85px;
                        color: #FC5145;
                        margin-left: -8rem;

                    }

                    .atom-two {
                        width: 60px;
                        height: 85px;
                        color: #3B8A6E;
                    }
                }
            }

            .courses {
                .list {
                    width: 50px;
                    height: 85px;
                    color: #FC5145;
                    margin-left: -8rem;
                }
            }

            .resume {
                .file {
                    width: 40px;
                    height: 85px;
                    color: #3B8A6E;
                    margin-left: -8rem;
                }

                .clip-1 {
                    width: 40px;
                    height: 85px;
                    color: #052941;
                }

                .clip-2 {
                    width: 40px;
                    height: 85px;
                    color: #EBB448;
                }

                .clip-3 {
                    width: 40px;
                    height: 85px;
                    color: #FC5145;
                }
            }

            .resources {
                .open-1 {
                    width: 50px;
                    height: 85px;
                    color: #3B8A6E;
                }

                .open-2 {
                    width: 50px;
                    height: 85px;
                    color: #FC5145;
                }
            }

            h3 {
                font-family: Poppins;
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                text-align: left;
            }

            .guides-collections {
                width: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                // gap: 20px;

                .guidesdetails {
                    width: 400px;
                    height: auto;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    box-shadow: rgba(0, 0, 0, 0.25) 1px 2px 2px 0.5px;
                    transition: background-color 0.3s ease;
                    padding: 1rem;
                    background-color: white;

                    .img {
                        h3 {
                            font-family: Poppins;
                            font-size: 22px;
                            font-weight: 600;
                            line-height: 30px;
                            text-align: left;

                        }
                    }

                    .heading {
                        font-family: Poppins;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                    }

                    .content {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: #3183FF;
                        margin-left: -4rem;
                        display: flex;
                        align-items: center;

                        svg {
                            width: 25px;
                            height: 50px;
                            color: #3183FF !important;
                        }

                        a {
                            text-decoration: none;
                            font-size: 18px;
                            color: #3183FF;
                        }
                    }
                }
            }

            .help_resources {
                .guides-collections {
                    p {
                        font-size: 20px;
                    }
                }
            }
        }

        .guide-section-details {
            .guides {
                .img {
                    svg {
                        width: 60px;
                        height: 85px;
                        color: #EA8254;
                    }
                }
            }

            .personal {
                .img {
                    .filepen {
                        width: 60px;
                        height: 85px;
                        color: #EBB448;
                    }

                    .atom {
                        width: 60px;
                        height: 85px;
                        color: #FC5145;
                        margin-left: -8rem;

                    }

                    .atom-two {
                        width: 60px;
                        height: 85px;
                        color: #3B8A6E;
                    }
                }
            }

            .courses {
                .list {
                    width: 50px;
                    height: 85px;
                    color: #FC5145;
                    margin-left: -8rem;
                }
            }

            .resume {
                .file {
                    width: 40px;
                    height: 85px;
                    color: #3B8A6E;
                    margin-left: -8rem;
                }

                .clip-1 {
                    width: 40px;
                    height: 85px;
                    color: #052941;
                }

                .clip-2 {
                    width: 40px;
                    height: 85px;
                    color: #EBB448;
                }

                .clip-3 {
                    width: 40px;
                    height: 85px;
                    color: #FC5145;
                }
            }

            .resources {
                .open-1 {
                    width: 50px;
                    height: 85px;
                    color: #3B8A6E;
                }

                .open-2 {
                    width: 50px;
                    height: 85px;
                    color: #FC5145;
                }
            }

            h3 {
                font-family: Poppins;
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                text-align: left;
            }

            .guides-collections {
                width: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                // gap: 20px;

                .guidesdetails {
                    width: 400px;
                    height: auto;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    box-shadow: rgba(0, 0, 0, 0.25) 1px 2px 2px 0.5px;
                    transition: background-color 0.3s ease;
                    padding: 1rem;
                    background-color: white;

                    .img {
                        h3 {
                            font-family: Poppins;
                            font-size: 22px;
                            font-weight: 600;
                            line-height: 30px;
                            text-align: left;

                        }
                    }

                    .heading {
                        font-family: Poppins;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                    }

                    .content {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: #3183FF;
                        margin-left: -4rem;
                        display: flex;
                        align-items: center;

                        svg {
                            width: 25px;
                            height: 50px;
                            color: #3183FF !important;
                        }

                        a {
                            text-decoration: none;
                            font-size: 18px;
                            color: #3183FF;
                        }
                    }
                }
            }

            .help_resources {
                .guides-collections {
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
}

.mobile-sub-category {
    display: none;
}

@media (max-width: 1600px) {
    .help-section {
    margin-top: 5rem;
        .m-5 {
            margin: 1.5rem !important;
        }
        .help-heading {
            h3 {
                font-size: 35px;
                line-height: 70px;
                margin: 0;
            }
        }
    }
}
@media (max-width: 900px) {
     .help-sub-section {
        .col-3 {
            width: 100% !important;

        }

        .col-9 {
            width: 100% !important;
        }

        .category-section {
            .mb-6 {
                margin-bottom: 1rem !important;
            }
        }

        .border-right-3 {

            border-width: 0px !important;
        }

            .guide-section-details {
                display: none;
            }
    }

    .sub-category {
        display: none;
    }

    .mobile-sub-category {
        display: block;
        .mob-guides-section{       
        .guides-collections {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            // gap: 20px;

            .guidesdetails {
                width: 400px;
                height: auto;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                box-shadow: rgba(0, 0, 0, 0.25) 1px 2px 2px 0.5px;
                transition: background-color 0.3s ease;
                padding: 1rem;
                background-color: white;

                .img {
                    h3 {
                        font-family: Poppins;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 30px;
                        text-align: left;

                    }
                }

                .heading {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                }

                .content {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #3183FF;
                    display: flex;
                    align-items: center;

                    svg {
                        width: 25px;
                        height: 50px;
                        color: #3183FF !important;
                    }

                    a {
                        text-decoration: none;
                        font-size: 18px;
                        color: #3183FF;
                    }
                }
            }
        }

        .guides {
            .img {
                svg {
                    width: 50px;
                    height: 85px;
                    color: #EA8254;
                }
            }
        }

        .personal {
            .img {
                .filepen {
                    width: 50px;
                    height: 85px;
                    color: #EBB448;
                }

                .atom {
                    width: 50px;
                    height: 85px;
                    color: #FC5145;
                    margin-left: -8rem;

                }

                .atom-two {
                    width: 50px;
                    height: 85px;
                    color: #3B8A6E;
                }
            }
        }

        .courses {
            .list {
                width: 50px;
                height: 85px;
                color: #FC5145;
                margin-left: -8rem;
            }
        }

        .resume {
            .file {
                width: 40px;
                height: 85px;
                color: #3B8A6E;
                margin-left: -8rem;
            }

            .clip-1 {
                width: 40px;
                height: 85px;
                color: #052941;
            }

            .clip-2 {
                width: 40px;
                height: 85px;
                color: #EBB448;
            }

            .clip-3 {
                width: 40px;
                height: 85px;
                color: #FC5145;
            }
        }

        .resources {
            .open-1 {
                width: 50px;
                height: 85px;
                color: #3B8A6E;
            }

            .open-2 {
                width: 50px;
                height: 85px;
                color: #FC5145;
            }
        }
            .guides-collections {
                justify-content: center !important;
            }
        }
    }
}

@media (max-width: 500px) {
    .help-sub-section {
        .m-5 {
            margin: 1rem !important;
        }

        .pl-5 {
            padding-left: 1rem !important;
        }

        .main-details .guides-section .guides-collections .guidesdetails {
            .content {
                margin-left: -2rem !important;
            }
        }

        .guides-section {
            .personal .img .atom {
                margin-left: 0rem !important;
            }

            .courses .list {
                margin-left: 0rem !important;
            }

            .resume .file {
                margin-left: 0rem !important;
            }
        }

        .help_resources {
            .mb-6 {
                margin-bottom: 1rem !important;
            }
        }
    }

}