.t2-template {
    border: 3px solid #000000;
    padding: 2rem;
    border-radius: 20px;
    min-height: 1000px;
    max-height: 1000px;
    overflow: scroll;
}

// .t2-template-content {
//     min-height: 700px;
//     max-height: 700px;
//     overflow: scroll;
// }

.t2-t-content {
    padding: 30px 0px;
}

.t2-left {
    padding: 20px;
}

.t2-right {
    border-left: 1px solid #FC5145;
    padding: 10px;
}

.t2-name {
    text-align: center;
    padding: 10px;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 90% */
}

.t2l-label {
    padding: 10px 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
}

.t2l-details{
    padding: 10px 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.t2personal-details {
    border-bottom: 1px solid #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #FC5145;
    padding: 15px;
}

.t2-p-details{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    padding: 2px 6px 2px 6px;
}

.t2r-sections {
    padding: 10px 5px;
}

.t2r-label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    border-bottom: 1px solid #052941;
    padding-bottom: 4px;
}

.t2r-content {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    padding-top: 8px;
}

.t2r-subcontent{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width:767px) {
    .t2-template {
        padding: 1rem !important;

        .t2personal-details {
            padding: 10px;
        }

        .t2-p-details {
            font-size: 15px;
        }

        .t2-left {
            .t2l-label {
                font-size: 15px;
            }

            .t2l-details {
                padding: 5px 0px;
                font-size: 14px;
            }
        }

        .t2-right {
            .t2r-sections {
                .t2r-label {
                    font-size: 15px;
                }

                .t2r-content {
                    font-size: 14px;
                }

                .t1r-content {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .t2-template {
        padding: 0.5rem !important;

        .pdf-button {
            .p-button {
                font-size: 14px !important;
            }
        }
    }

    .t2-name {
        font-size: 25px;
    }

    .t2personal-details {
        padding: 0px !important;
    }

    .t2-p-details {
        font-size: 12px !important;
    }

    .t2-left {
        padding: 5px !important;

        .t2l-label {
            padding: 5px 0px !important;
            font-size: 12px !important;
        }

        .t2l-details {
            padding: 5px 0px;
            font-size: 11px !important;
        }
    }

    .t2-right {
        padding: 0px 5px !important;

        .t2r-sections {
            padding: 5px 5px !important;

            .t2r-label {
                font-size: 12px !important;
            }

            .t2r-content {
                font-size: 11px !important;
            }

            .t1r-content {
                font-size: 11px !important;
            }

        }
    }
}