.pie-chart-container {

    .wait-section {
        .wait-img img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }

        .wait-content p {
            font-family: Poppins;
            font-size: 16px;
            color: #000000;
        }

    }

    .dropdown-section {
        .p-dropdown-items {
            margin: 15px 0px 5px !important;
        }
    }

}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #052941 !important;
    color: white !important;
}

.pie-chart-container .pie-chart-section{
    .pie-chart{
        box-sizing: border-box !important;
        display: block !important;
        height: 550px !important;
        width: 550px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .pie-chart-container {
        .py-4 {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }
        .pie{
            height: 58vh !important;
        }
    }
}

@media screen and (max-width:1008px) {
    .pie-chart-section {
        .pie-chart {
            height: 530px !important;
            width: 530px !important;
        }
    }
}

@media only screen and (max-width: 930px) {
    .pie-chart-container {
        .pie{
            height: 70vh !important;
        }
    }
}

@media only screen and (max-width: 750px) {
    .pie-chart-container {
        .p-6 {
            padding: 1rem !important;
        }

        .wait-section .wait-content p {
            font-size: 14px;
        }

        .pie-chart-section {           
            .pie-chart {
                height: 400px !important;
                width: 400px !important;
            }
        }
        .dropdown-section {
            display: grid;

            p {
                font-size: 13px;
            }
        }

        .py-4 {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
        }

        .px-4 {
            padding-left: 0rem !important;
            padding-right: 0rem !important;
        }
    }
}