.resume-builder {
    margin: 2rem;
    margin-top: 130px;
}

.resume-templates{
    margin-top: 2rem !important;
    display: flex;
    place-items: center;
    align-items: center;
}

@media screen and (max-width:600px) {
.resume-builder h1{
    font-size: 25px !important;
}
}