.personal-pathway {
  padding-top: 120px;
}

.pp-header-content {
  // height: 100%;
  width: 100%;
  padding: 2rem;
}

.pp-h-title {
  margin-top: 20px;
  color: #000;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 5px solid #ebb448;
}

.pp-h-p {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pp-image {
  width: 100%;
  box-shadow: 1em 1em #fc5145;
  position: relative;
  padding-right: 0px;
}

.pp-content-section {
  margin: 4rem 0rem !important;
  background: #052941;
  padding: 3rem;

  img {
    margin-top: -222px;
    width: 75%;
  }
}

.pp-c-title {
  color: #fff;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pp-c-p {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pp-left {
  display: grid;
  align-items: center;
}

.pp-position {
  position: absolute;
}

.space {
  padding: 4rem !important;
}

@media screen and (max-width: 1635px) {
  .pp-content-section {
    margin: 4rem 0rem !important;
    background: #052941;
    padding: 0rem;

    img {
      margin-top: -202px;
      width: 70%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .pp-h-title {
    font-size: 40px;
  }

  .pp-content-section {
    margin: 4rem 0rem !important;
    background: #052941;
    padding: 0rem;

    img {
      margin-top: -140px;
      width: 60%;
    }
  }
}

@media screen and (max-width: 1220px) {
  .pp-h-title {
    font-size: 36px;
  }

  .pp-c-title {
    font-size: 40px;
  }

  .pp-h-p,
  .pp-c-p {
    font-size: 18px;
  }

  .pp-content-section img {
    margin-top: -50px;
    width: 53%;
  }

}

@media screen and (max-width:991px) {
  .personal-pathway {
    padding-top: 70px;
  }

  .space {
    padding: 0rem 3rem !important;
    display: flex;
    justify-content: center;
  }

  .pp-position {
    position: relative !important;
    display: flex;
    justify-content: center;
    margin-bottom: -156px;
    margin-top: -70px;
  }

  .pp-left-section {
    order: 2;
  }

  .pp-content-section img {
    width: 80%;
  }

  .pp-image {
    width: 80%;

  }
}

@media screen and (max-width:490px) {
  .pp-h-title {
    margin-top: 0;
    font-size: 28px;
  }

  .pp-c-title {
    font-size: 36px;
  }

  .pp-h-p,
  .pp-c-p {
    text-align: unset;
  }

  .pp-position {
    position: relative !important;
    display: flex;
    justify-content: center;
    margin-bottom: -156px;
    margin-top: -50px;
  }

  .pp-content-section img {
    width: 100%;
    margin-bottom: 7rem;
  }
}