.category-btn {
    border-radius: 10px;
    border: 3px solid #052941;
    padding: 20px;
    color: #052941;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
}

.category-btn-active {
    border-radius: 10px;
    border: 3px solid #052941;
    background: #052941;
    padding: 20px;
    color: #F9F9F9;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    // gap: 60px;
}

.sub-categories{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0px 20px 0px !important;
}

.sub-categories-label {
    line-height: 23px;
}

.top-section-buttons.flex {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
  }
  
.sublabels-container{
    gap: 10px;
}

.elective-subjects {
    .p-accordion .p-accordion-header .p-accordion-header-link {
        border-bottom: 4px solid #EA8254 !important;
    }
    .header-icon {
        color: #EA8254;
    }
    .p-accordion .p-accordion-content {
        border-bottom: 4px solid #EA8254 !important;
    }
}

@media screen and (max-width:1440px){
    .top-section-buttons.flex {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr) !important;
      }
}

@media screen and (max-width:1008px) {
    .mob-subjects {
        margin-top: 20px;
        width: 100%;
        border-top: 4px solid #ea8254 !important;
        .p-accordion .p-accordion-header .p-accordion-header-link {
            border-bottom: 4px solid #ea8254 !important;
        }
        .header-icon {
            color: #ea8254;
            font-size: 24px !important;
        }
        .p-accordion .p-accordion-content {
            border-bottom: 0px !important;
        }
        .p-accordion-header-text {
            font-size: 24px;
        }
        .header-text{
            .p-accordion .p-accordion-header .p-accordion-header-link {
                border-top: 4px solid #ea8254 !important;
            }
        }
    }
    .elective-hide{
        display: none;

        p {
            color: #434343;
        }
    }
    .mob-top-section-buttons.flex {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
    }   
    .categories {
        display: block !important;
        gap: 10px !important;            
    } 
    .mob-sublabels-containers{
        display: grid !important;
        font-size: 16px !important;
    }
    .mob-sublabels-container{
        font-size: 16px !important;
    }
   
}

@media screen and (max-width:500px) {
    .mob-subjects {
        font-size: 18px;
        p{
            font-size: 16px;
        }  
        .mob-sublabels-containers{
            font-size: 14px;
        }      
    }   
}