.modeling {
  padding-top: 100px;
}

.m-h-title {
  margin-top: 20px;
  color: #000;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 5px solid #3B8A6E;
}

.m-h-image {
  width: 100%;
  box-shadow: 1em 1em #052941;
  position: relative;
  padding-right: 0px;
}

.m-c-image {
  width: 100%;
  box-shadow: -1em -1em #3B8A6E;
  position: relative;
  padding-right: 6px;
}


.m-c-title {
  margin-top: 20px;
  color: #000;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 5px solid #052941;
}

.m-content-section {
  margin: 2rem 0rem !important;
}

@media screen and (max-width: 991px) {
  .m-h-title {
    margin-top: 0;
    font-size: 36px;
  }

  .m-h-image {
    width: 80%;
  }

  .m-content-section img {
    width: 80%;
  }
}

@media screen and (max-width: 490px) {
  .m-h-title {
    font-size: 30px;
  }

  .m-h-image {
    width: 100%;
  }

  .m-content-section img {
    width: 100%;
  }
}