.awards-section {
  .date{
    .p-button {
      padding: 0px !important;
  }
}
  .awards-image {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }
  .description {
    width: 100% !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    color: black;
    border: 1px solid #B9B9B9 !important;
  }
}

.addextra-text {
  font-size: 18px !important;
  cursor: pointer;
  text-align: right;
}

@media (max-width: 1008px) {
  .datatable-container {
    display: none;
  }

  .extra-activities-tables {
    ul {
      li {
        list-style: none;
        border-radius: 10px !important;
        background: #fff !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
        margin: 10px;
      }

      .awards-heading {
        background: rgba(234, 130, 84, 0.3) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 10px !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
      }
    }

    .hide-on-large-screens {
      padding: 0px !important;
    }
  }
  .ar-p {
    font-size: 18px;
  }

  .awards-section .notes-alignment {
    display: flex;
    flex-direction: column !important;

    .col {
      font-size: 18px;
    }
  }
}

@media (max-width: 600px) {
  .awards-recognition-heading .award {
    width: 100% !important;
  }
  .awards-recognition-heading .date {
    width: 100% !important;
  }

  .awards-section {
    .awards-recognition-heading {
      .col-6 {
        width: 100% !important;
      }
    }
  }

  .awards-section .notes-alignment {
    padding-top: 0 !important;
  }
}

@media (max-width: 500px) {
  .extra-activities-tables ul .awards-heading {
    font-size: 16px;
  }
  ul {
    font-size: 16px;
    .py-4 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    .px-4 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  }
  .awards-section {
    .awards-recognition-heading {
      font-size: 16px;
    }
  }
  .ar-p {
    font-size: 16px;
  }
}