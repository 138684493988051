.writers-letters-section {
    .writers-image {
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }
    }
}
.writers-letters-heading {
    .writer-letters-subheading {
        display: flex;
        flex-wrap: wrap;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        color: black;
        border: 1px solid #B9B9B9 !important;
    }
}

.addextra-text {
    font-size: 18px !important;
    cursor: pointer;
}

@media (max-width: 1008px) {
    .datatable-container {
        display: none;
    }

    .extra-activities-tables {
        ul {
            li {
                list-style: none;
                border-radius: 10px !important;
                background: #FFF !important;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
                margin: 10px;
            }

            .letters-heading {
                background: rgba(234, 130, 84, 0.30) !important;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 10px !important;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
            }
        }

        .hide-on-large-screens {
            padding: 0px !important;
        }
        .wr-p {
            font-size: 18px;
        }
    }

    .writers-letters-section {
        .writers-letters-heading {
            .writer-letters-subheading {
                display: grid !important;
                .col-4 {
                    width: 100% !important;
                }
            }
        }

        .notes-alignment {
            display: flex !important;
            flex-direction: column;

            .col {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 600px) {
    .extra-activities-tables ul .letters-heading {
        font-size: 16px;
    }

    ul {
        font-size: 16px;

        .py-4 {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }

        .px-4 {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }

    .writers-letters-section {
        .writers-letters-heading {
            font-size: 16px;
        }
        .notes-alignment { 
            padding-top: 0 !important;
        }
    }
    .wr-p {
        font-size: 16px;
    }
}