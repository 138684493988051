.valid {  
  color:#036E35;
}

.invalid {
  color:#EF5454; 
}



.account-container {
  width: 100%;

  .spinner-section {
    .spinner-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .p-progress-spinner-circle {
      stroke: #052941 !important;
      stroke-width: 5 !important;
      animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
    }
  }

  .errorLable {
    height: 30px;
    font-size: 14px;
    color: #E04A4A;
    display: flex;
    align-items: center;
  }

    .button-container-sec {
      gap: 15px;
  
      .resend {
        height: 5vh;
        display: flex;
        justify-content: end;
        align-items: center;
        color: #052941;
  
        span {
          cursor: pointer;
        }
      }
    }

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      padding: 20px;
    }
  }

  .signup-card-section {
    .image-field {
      background-color: #ea8254;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      }
    }

    .image-field-sec {
      background-color: #ea8254;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 70vh;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      }
    }

    .form-container {
      height: 85vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .account {
        width: 100%;
      }

      p {
        font-size: 24px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 11px;
      }

      .heading-content {
        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #808080;
        }
      }

      label {
        font-size: 16px;
        color: #000000;

        ::placeholder {
          color: #808080;
          font-size: 16px;
          opacity: 1;
        }
      }

      .field .col {
        padding: 0.5rem;
      }

      .field {
        display: grid;
        margin: 10px;
      }

      .field-checkbox {
        line-height: 18px;

        label {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #808080;

          a {
            text-decoration: none;
            color: #1067b8;
          }
        }
      }

      .button-container {
        display: grid;
        margin: 25px 10px;
      }

      .button-container-sec {
        display: grid;
        margin: 10px;
      }
    }

    .form-container-section {
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .confirm {
        width: 100%;

        .noteMessages {
          .p-message-wrapper {
            justify-content: flex-start !important;
          }
        }
      }

      p {
        font-size: 24px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 11px;
      }

      .heading-content {
        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #808080;
        }
      }

      label {
        font-size: 16px;
        color: #000000;

        ::placeholder {
          color: #808080;
          font-size: 16px;
          opacity: 1;
        }
      }

      .field .col {
        padding: 0.5rem;
      }

      .field {
        display: grid;
        margin: 10px;
      }

      .field-checkbox {
        line-height: 18px;

        label {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #808080;

          a {
            text-decoration: none;
            color: #1067b8;
          }
        }
      }

      .button-container {
        display: grid;
      }

      .button-container-sec {
        display: grid;
        margin: 10px;
      }
    }
  }
  .p-password {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.p-password .p-password-show-icon, .p-password .p-password-hide-icon {
  margin-right: 20px !important;
}
}

.p-input-icon-right .p-inputtext {
  padding-right: 2rem;
  width: 100%;
}

.account-container .p-card .p-card-body {
  padding: 0rem !important;
}

.account-container .p-card .p-card-content {
  padding: 0px !important;
}

.error .p-inputtext {
  border: 1px solid #E04A4A !important;
}

.email-error {
  border: 1px solid #E04A4A !important;
}

@media (max-width: 767px) {
  .account-container {
    .signup-card-section {
      .image-field {
        display: none;
      }

      .image-field-sec {
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .account-container .p-card .p-card-body {
    padding: 0rem !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .account-container {
    .signup-card-section {
      margin: -7px !important;
      box-shadow: none !important;
      border-top: 1px solid #052941B2;
    }
  }

  .account-container .signup-card-section .form-container .field-checkbox label {
    line-height: 25px !important;
  }
}