.p-datatable .p-datatable-tbody > tr > td {
    text-align: center !important;
}


.hs-datatable {
    display: block;
}

.mobile-highschool{
    display: none;
}

@media screen and (max-width:1008px) {
    .checklist-card-section{
        .p-card{
            box-shadow: none !important;
        }

        .graduation-container .note-alignment{
            flex-wrap: wrap !important;
            flex-direction: row-reverse;
        }
    }
    .checklist-card.m-4{    
            margin: 0rem !important;        
    }

    .hs-datatable {
        display: none;
    }
   
}

@media screen and (max-width:500px) {
    .checklist-card-section{
        .p-button {
            font-size: 16px !important;
            padding: 0.5rem 0.5rem !important;
        }
        .pi-plus {
            font-size: 18px !important;
        }

        .graduation-container {
            .p-datatable .p-datatable-tbody > tr > td {
                font-size: 16px !important;
                padding:  1rem 0.1rem !important;
            }
            .note-alignment {
                margin-top: 0 !important;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .checklist-card-section {
        .extra-activities-form{
            .desktop-highschool{
                display: none;
             }
             .mobile-highschool{
                 display: block;
             }
        }
        
    }
    .accordion-content{
        .teacher-notes-section{
            p{
                font-size: 18px;
            }
        }
    }
}