.Student-GR-card-container .p-card .p-card-body {
	box-shadow: 0px 6px 15px 0px #CCCCCC;
	border-radius: 10px;
}

.Student-GR-card-container .p-card .p-card-content {
	padding: 10px !important;
}

.Student-GR-card-container {
	.p-panel .p-panel-header {
		border: 0px !important;
		background: rgba(255, 255, 255, 1) !important;
		color: #052941 !important;
	}

	.p-panel .p-panel-content {
		border: 0px !important;
		background: #ffffff;
		padding: 0 1rem 1rem !important;
	}
}

.p-progressbar {
	height: 10px !important;
	color: #CCCCCC !important;
	border-radius: 50px !important;
}

.p-progressbar-determinate .p-progressbar-label {
	display: none !important;
}

.p-progressbar .p-progressbar-value {
	background: #FC5145 !important;
	color: #FC5145;
	border-radius: 50px;

}

.gr-text {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: 0em;
	text-align: left;
	color: #000000;
	margin-bottom: 10px;
}

.pb-text {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
}

.course {
	color: #000000;
	font-size: 14px;
}

.course-bar {
	margin-top: 5px;
}

.each-pb {
	margin: 10px 0px;
}

// .circular-progress-bar {
	// padding-left: 16px !important;
	// margin-top: 24px;
// }

.CircularProgressbar-path {
	stroke: #3B8A6E !important;
}

.CircularProgressbar-trail {
	stroke: #E3E5E4 !important;
}

.CircularProgressbar-text {
	fill: #000000 !important;
}

@media only screen and (max-width: 1400px) {

	// .circular-progress-bar {
		// width: 170px !important;
		// height: 170px !important;
	// }

	.gr-text {
		font-size: 22px;
	}

	.course {
		font-size: 14px;
	}

	.course-num {
		font-size: 13px;
	}
}

@media only screen and (max-width: 1140px) {
	.circular-progress-bar {
		width: 150px !important;
		height: 150px !important;

		.circular-credits {
			font-size: 18px !important;

			span {
				font-size: 25px !important;
			}
		}
	}

}


@media only screen and (max-width: 890px) {
	.Student-GR-card-container {
		.GR-card-tab {
			h3 {
				font-size: 16px;
			}
		}

		.circular-progress-bar-tab {
			width: 170px !important;
			height: 170px !important;

			.circular-credits {
				font-size: 18px !important;

				span {
					font-size: 25px !important;
				}
			}
		}

		.progressbars-section-tab {
			.course {
				margin-left: -80px !important;
			}

			.course-bar {
				margin-left: -30px !important;
			}

			.course-num {
				margin-left: 20px !important;
			}
		}
	}
}


@media only screen and (max-width: 767px) {
	.progressbars-section {
		.p-bars {
			.tab-course-section {
				display: grid;
				gap: 0px;
			}
			.sub-heading {
				font-size: 16px !important;
			}
		}
	}
}