@media screen and (max-width: 1008px) {
  .complete-course-container.flex {
    display: grid !important;  
    .col-3{
        width: 100% !important;
    }  
  }
  .Extra-activities-form-footer{
    .course-button{
        border: 0px !important;
        color:#1575E5 !important ;
      }
  } 
}

.complete-courses{
  .p-datatable .p-datatable-tbody > tr > td {
    color: black;
}
}

.cc-note {
  color: #6C757A;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}


@media (max-width: 1008px) {
  .datatable-container {
      display: none;
  }   
  .complete-course-section{
    font-size: 18px !important;
  }   
  
  .extra-activities-tables {   
      ul {
          li {
              list-style: none;
              border-radius: 10px !important;
              background: #FFF !important;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
              margin: 10px;
          }         

           .activity-heading {
              background: rgba(234, 130, 84, 0.30) !important;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px;
              border-radius: 10px !important;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
          }
      }



      .hide-on-large-screens {
          padding: 0px !important;
      }
  }
  .course-complete-img{
    font-size: 16px !important;      
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }
    
  }
    
  }


  @media screen and (max-width:500px){
    .complete-course-section{
      font-size: 16px !important;
    } 
      .complete-courses {
        .p-multiselect-panel {
          max-width: 350px !important;
        }
      }
  }