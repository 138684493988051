.all-scores-form{
    margin: 1rem 2rem 4rem 2rem;
}

.header-text {
    color: #000000;
    font-size: 24px;
}

.Extra-activities-form-footer{
    margin: 2rem 0;
    display: flex;
    justify-content: flex-end;
}

.addextra-icon{
    color: #1067B8;
    font-size: 16px !important;
}

.addextra-text{
    color: #1067B8;
    font-size: 16px !important;
}

.all-scores-form .p-datatable .p-datatable-thead > tr > th{
    background: #052941 !important;
    border: 1px solid #B9B9B9 !important;
    color: #ffff !important;
}

.all-scores-form .p-datatable {
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.all-scores-section {
    .pi-plus {
        font-size: 24px !important;
    }
}

.extra-activities-section .p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #B9B9B9 !important;
}

.mobile-scores{
    display: none;
}

@media screen and (max-width:1008px) {
    .all-scores-section {
        .p-card {
            box-shadow: none !important;
        }
        .m-4{
            margin: 0px !important;
        }
    }
    .all-scores-form {
        margin:0rem !important;
    }
}

@media screen and (max-width:500px) {
    .all-scores-section {
        .pi-plus {
            font-size: 18px !important;
        }
    }
    .extra-activities-section{
        .hide-on-large-screens{
            .px-6 {
                padding-left: 1rem !important;
                padding-right: 1rem !important;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .all-scores-section {
        .all-scores-form{
            .desktop-scores{
                display: none;
             }
             .mobile-scores{
                 display: block;
             }
        }
        
    }
    .accordion-content{
        .teacher-notes-section{
            p{
                font-size: 18px;
            }
        }
    }
}