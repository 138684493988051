.stack-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        img {
            width: 200px;
            height: 100%;
        }
    }

    ul {
        list-style: none;
    }

    .menu-items {
        display: flex;
        align-items: center;
        // gap: 35px;
        margin: 0;
        padding: 0;

        .profileIcon {
            display: flex;
            align-items: center;
            gap: 3px;
            cursor: pointer;

            svg:first-child {
                width: 40px;
                height: 40px;
            }
        }

        .active {
            font-weight: 700;
            border-bottom: 3px solid #367f65;
        }
    }

    li {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-size: 18px;

        span {
            margin-right: 20px;
        }
    }

    .avatar-menu-item ul.submenu {
        background: bisque;
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    .p-overlaypanel-content {
        ul.profile-menu-list {
            list-style: none !important;
        }
    }
}

ul.profile-menu-list {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    cursor: pointer;
    line-height: 2;

    li {
        // gap: 10px;
        display: flex;
        align-items: center;
        padding: 5px;

        span {
            padding-right: 10px;
        }
    }
}

.container {
    width: 100%;
    margin: 0 auto;
    // padding: 0 5rem;
    padding: 20px 40px;
    // padding: 40px;
    border-bottom: 5px solid rgba(5, 41, 65, 0.5);
    height: auto;
    box-shadow: 0 6px 8px rgba(0, 0, 0, .10196078431372549);
    background: hsla(0, 0%, 100%, .7803921568627451);
    position: fixed;
    top: 0;
    z-index: 999;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    min-height: 90px;

}

.p-overlaypanel {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
    display: none !important;
}

.hamburger-menu {
    display: none;
}

.bar {
    width: 25px;
    height: 5px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
}

.bar.active:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.bar.active:nth-child(2) {
    opacity: 0;
}

.bar.active:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

.right-content {
    display: none;
}

.mobile-header {
    display: none;
}

@media (max-width: 1108px) {
    .container {
        padding: 0;
        border-bottom: none;
        min-height: 80px;

        .header {
            padding: 1rem;
            border-bottom: 5px solid rgba(5, 41, 65, 0.5);
        }
    }
}

@media (max-width: 1008px) {


    .menu-items {
        display: none !important;
    }

    .menu-items.show {

        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .hamburger-menu {
        display: flex;
        align-items: center;
    }

    .container {
        padding: 0;
        border-bottom: none;
        min-height: 80px;

        .header {
            padding: 1rem;
            border-bottom: 5px solid rgba(5, 41, 65, 0.5);


            .menu-container .menu-toggle {
                display: block;
            }
        }
    }

    .right-section {
        width: 100%;
        height: 100vh;
        background: #052941;
        color: #fff !important;

        .right-content {
            display: grid;
            align-items: center;
            padding: 10px;

            .sec {
                display: grid;
                width: 100% !important;
                height: 100vh !important;

            }

            .avatar {
                display: grid;
                justify-content: flex-start;
                align-items: center;


                p {
                    font-size: 14px;
                }

                span {
                    font-size: 20px;
                }
            }

            li {
                list-style: none;
                cursor: pointer;
                font-size: 18px;
                gap: 20px;
                display: flex;
                align-items: center;

                &:first-child {
                    gap: 14px;
                }

                svg {
                    font-size: 22px;
                }
            }

            .Link a {
                color: #fff;
                text-decoration: none;

            }

            .mobile-menu-items {
                display: grid;
                gap: 25px;
            }
        }
    }
}

@media (max-width: 500px) {
    .stack-navbar .logo .logo-image.logo-open {
        width: 50%;
        height: 100%;
    }
}