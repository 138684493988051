.dataanalytice {
  padding-top: 100px;
}

.da-h-image {
  width: 70%;
  // position: relative;
  // padding-right: 6px;
  float: right;
}

.da-card-image {
  width: 50px;
  height: 50px;
}

.da-cards {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  padding: 2rem !important;
  margin: 20px;
}

.da-cards-section {
  padding: 20px 20px;
}

.da-content-section {
  margin: 2rem 0rem !important;
  background: #3b8a6e;
}

.da-c-img {
  text-align: center;
  padding: 2rem !important;
}

.da-card-text{
  margin-left: 14px;
}

@media screen and (max-width: 1440px) {
  .da-h-image {
    width: 100%;
    float: right;
  }
}

@media screen and (max-width: 1220px) {
  .da-img {
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .da-h-image {
    width: 50%;
  }
}

@media screen and (max-width: 490px) {
  .da-h-image {
    width: 80%;
  }

  .da-c-img {
    text-align: center;
    padding: 2rem !important;
    padding-top: 0 !important;
  }
}