.student {
    margin: 2rem;
    margin-top: 160px !important;
}

.hide-on-large-screens {
    display: none;
}


.student-dashboard {
    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }

    .p-panel .p-panel-header {
        border: 0px !important;
        background: rgba(255, 255, 255, 1) !important;
        color: #052941 !important;
    }

    .p-panel .p-panel-content {
        border: 0px !important;
        background: #ffffff;
        padding: 0 1rem 1rem !important;
    }
}

@media (max-width: 1108px) {
    .student {
        margin-top: 130px !important;
    }
}

@media (max-width: 1008px) {
    .student-dashboard .col-4 {
        display: none;
    }

    .student-dashboard .col-8 {
        width: 100% !important;
    }

    .student .desktop {
        display: none;
    }

    .student-form .desktop {
        display: none;
    }

    .hide-on-large-screens {
        display: block;
    }

    .student {
        margin-top: 50px !important;

        .tab-mob-List ul {
            padding: 0px;
            width: 100%;

            li {
                list-style: none;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                color: #052941 !important;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                cursor: pointer;
                border-bottom: 3px solid #3B8A6E;
                width: 100%;
            }
        }
        .tab-mob-student-form a{
            text-decoration: none;
            display: flex;
            align-items: center;

            svg {
                margin-left: -2rem;
                color: #3B8A6E;
                font-size: 24px;
            }
        }
    }
}

@media (max-width: 350px) {
    .student {
        margin: 1rem !important;
        margin-top: 60px !important;
    }
}

.mob-student-dashboard {
    .student {
        margin-top: 130px !important;
    }

    a {
        text-decoration: none;
        color: #1575E5;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .profile-student-name {
        font-size: 20px !important;
    }
}