.products_services {
    margin: 3rem;
}

.title {
    border-bottom: 10px solid #3B8A6E;
    width: 50%;
}

.main-title {
    color: #052941;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.content-section {
    margin-top: 1rem !important;
    padding: 10px;
}

.ps-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ps-right {
    padding-left: 20px !important;
}

.ps-image {
    width: 100%;
    box-shadow: -1em -1em #EA8254;
    position: relative;
    padding-right: 4px;
}

.icon-chevron-right {
    width: 30px;
    color: green
}

.ps-section {
    padding: 10px 0px;
    display: flex;
    border-bottom: 1px solid #929292;
}

.ps-title {
    color: #052941;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 5px;
}

.ps-subtitle {
    color: #052941;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.s-icon {
    height: 100%;
}

@media (max-width: 1200px) {
    .ps-title {
        font-size: 20px;
        margin-bottom: 4px;
        margin-top: 4px;
    }
    .ps-subtitle {
        font-size: 18px;
    }
}

@media (max-width: 490px) {
    .ps-title {
        font-size: 18px;
        margin-bottom: 4px;
        margin-top: 4px;
    }
    .ps-subtitle {
        font-size: 16px;
    }
    .ps-right {
        padding-left: 0px !important;
    }
}
