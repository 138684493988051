.Academic-Goals-container {
    margin: 2rem;
} 

.academic-pathways-form {
    margin: 1rem 1rem 4rem 1rem;
}

.pathway-radio-buttons {
    margin-top: 20px;
    border-left: 1px solid #D9D9D9;
}


.honor-label{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}

@media screen and (max-width:1008px) {
    .honor-section{
     display: none;
    }  
    .honors-container{
        font-size: 18px !important;
    }
  
 }

 @media screen and (max-width:500px){
    .honors-container{
        font-size: 16px !important;
    }
 }