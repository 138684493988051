.dropdown-label-act {
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding-right: 20px;
    padding-top: 6px;
}

.act-psat-sat-radio-buttons {
    margin-bottom: 20px;
    margin-top: 20px;
}


.all-scores-container {
    .p-datatable .p-datatable-tbody > tr > td {
        color: black;
    }
}

.extra-activities-section {
    .act-label {
        .p-button {
            padding: 0 !important;
        }
    }
}

@media (max-width: 1008px) {
    .datatable-container {
        display: none;
    }  
    .all-scores-container{
        font-size: 18px !important;      
    }    
    
    .extra-activities-tables {

        ul {
            li {
                list-style: none;
                border-radius: 10px !important;
                background: #FFF !important;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
                margin: 10px;
            }         

             .activity-heading {
                background: rgba(234, 130, 84, 0.30) !important;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 10px !important;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;              
            }
        }

 

        .hide-on-large-screens {
            padding: 0px !important;    
            .justify-content-evenly{
                justify-content: space-between !important;
                padding: 0px 20px !important;
            }        
        }
    }      
    }

 @media screen and (max-width:768px){
    .act-psat-sat-radio-buttons{
        .justify-content-right{
            justify-content: center !important;    
        }
    }
    .act-psat-sat-card-container .flex{
        display: block !important;       
    }
    .act-psat-sat-content{
        display: flex !important;     
        padding: 10px !important;
    }
    .act-container.grid{                    
        display: grid !important; 
    }
    .act-label{        
            width: 100% !important;        
    }
    .act-container{
       .w-full{
        width: 100%  !important;
       }
    }
    .act-container{
        .form-content{
            margin: 0px !important;
            width: 100% !important;
        }
        .dropdown-label-act {
            border-top: 0px solid #D9D9D9 !important;
            border-bottom: 0px solid #D9D9D9 !important;           
            padding-top: 0px !important;
        }  
    }
    .Extra-activities-form-footer{
        .scores-button{
            border: 0px !important;
            color:#1575E5 !important ;
          }
      }
  .psat-sat-act-img{
    font-size: 16px !important;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }
  }   
 }              
       
             
   
   


@media (max-width: 500px) {
    .extra-activities-tables ul .activity-heading {
      font-size: 16px;
    }
    .all-scores-container{
        font-size: 16px !important;
    }

    ul{
        font-size: 16px;
    .py-4 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;

    } 

    .px-4 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

}

.extra-activities-section{

.extra-heading{
    font-size: 16px;
}

}



}