.Academic-Goals-container {
    margin: 2rem;
} 

.academic-goals-form {
    margin: 1rem 1rem 4rem 1rem;
}

svg.p-icon.p-accordion-toggle-icon {
    display: none;
}
 
.p-accordion-header-text {
    width: 100% !important;
}

.accordion-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-text {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
}

.header-icon {
    color: #91BF82;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    border-bottom: 4px solid #91BF82 !important;
    background: none !important;
}

.p-accordion .p-accordion-content {
    border: none !important;
    border-bottom: 4px solid #91BF82 !important;
    background: none !important;
    color: #000000 !important;
    font-size: 20px !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
}

.p-accordion .p-accordion-tab {
    margin-bottom: 14px !important;
}

.acadamic-section .pi-plus {
    font-size: 24px !important;
    font-weight: 700;
}

.choose {
    margin-bottom: 20px;
}

.p-radiobutton {
    vertical-align: baseline !important;
}

.radio-buttons {
    margin-bottom: 20px;
}

.radiobutton-description {
    margin-left: 1rem;
    border-left: 2px solid #D9D9D9;
}

.form-footer {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.acadamic-section {
    .acedamic-options{
        display: flex !important;
        flex-wrap: wrap;
        .options-value{
            margin: 0.5rem !important;
        }
    }
}

@media screen and (max-width:1008px) {
    .acadamic-section {
        .p-card {
            box-shadow: none !important;
        }
        .m-4{
            margin: 0px !important;
        }

        .gap-6 {
            gap: 1rem !important;
        }
        .options-value {
            label {
                font-size: 16px !important;
            }
        }
       
    }

    .acadamic-section {
        .pi-plus {
            font-size: 18px !important;
        }
    }
    .header-text {
        font-size: 20px !important;
    }

    .radiobutton-description {
        border-left: 0px !important;
    }
    .academic-goals-form .p-accordion .p-accordion-content {
        font-size: 16px !important;
        width: auto;
    }
    .academic-goals-form {
        margin: 0px !important;
    }
}

.mobile-academic{
    display: none;
}

@media screen and (max-width:500px) {
   
    .header-text {
        font-size: 18px !important;
    }
    .p-button {
        font-size: 16px !important;
        padding: 0.5rem 0.5rem !important;
    }
}

@media screen and (max-width:767px) {
    .desktop-academic{
       display: none;
    }
    .mobile-academic{
        display: block;
    }
}