.founder-section {
    background: #052941;
    width: 100%;

    .founder {
        .founder-img {
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 20rem;
                border-radius: 100%;
            }
        }
    }

    .founder-details-section {
        p {
            font-family: Poppins;
            font-size: 32px;
            color: #FFFFFF;
            margin: 0px !important;
        }

        span {
            font-family: Poppins;
            font-size: 20px;
            color: #FFFFFF;
        }
    }

    .founder-para {
        p {
            font-family: Poppins;
            font-size: 20px;
            color: #FFFFFF;
            border-top: 6px solid #91BF82;
        }

        span {
            font-family: Poppins;
            font-size: 20px;
            color: #FFFFFF;
        }
    }
}

@media (max-width: 1400px) {
    .founder-section .founder-para {
        p {
            font-size: 18px !important;
        }

        span {
            font-size: 18px !important;
        }
    }
}


@media (max-width: 1200px) {
    .founder-section {
        .founder {
            display: grid !important;

            .founder-img {
                img {
                    max-width: 100% !important;
                }
            }
        }

        .founder-details-section {
            p {
                font-size: 25px;
            }

            span {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 500px) {
    .founder-section {
        .m-5 {
            margin: 1rem !important;
        }

        .founder {
            .p-5 {
                padding: 1rem !important;
            }
        }

        .founder-details-section {
            p {
                font-size: 22px;
            }

            span {
                font-size: 16px;
            }
        }

        .founder-para {
            p {
                font-size: 16px !important;
                display: flex;
                text-align: justify !important;
            }

            span {
                font-size: 16px !important;
                display: flex;
                text-align: justify !important;
            }

            .link {
                font-size: 16px !important;
                padding-Left: 0px !important;
            }
        }
    }
}