.main-sec-TM {
    width: 100%;
    height: auto;
    padding: 4rem;
    display: flex;
    flex-direction: column;

    .headerSec {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .left {
            min-width: 230px;

            span {
                min-width: 230px;
                font-size: 24px;
                font-weight: 500;
                border-bottom: 5px solid #91BF82;
            }
        }

        .right {
            font-size: 20px;
            font-weight: 400;
            margin: 20px;
        }
    }

    .team-sec {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .team-member {
            width: 300px;
            height: 380px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
            margin: 20px;
            .img-sec {
                width: 247px;
                height: 268px;
                margin: auto;

                img {
                    width: -webkit-fill-available;
                    height: auto;

                    // width: 100%;
                    // height: 30vh;
                    // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
                    // clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
                    background-size: cover;
                    background-position: center center;
                }

            }

            .name {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 700;
            }

            .position {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                // align-items: center;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

}

.main-sec {
    width: 100%;
    height: auto;
    padding: 4rem;
    display: flex;
    flex-direction: column;

    .headerSec {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .left {
            min-width: 230px;

            span {
                min-width: 230px;
                font-size: 24px;
                font-weight: 500;
                border-bottom: 5px solid #91BF82;
            }
        }

        .right {
            font-size: 20px;
            font-weight: 400;
        }
    }

    .condent-sec {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .mission {
            width: 400px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: end;

            .img {
                width: max-content;
                position: relative;
                top: 40px;
                right: 40px;
                background: #fff;
            }

            .head {
                font-size: 20px;
                font-weight: 600;
                padding: 0 70px;
                position: relative;
                top: 70px;
            }

            .note {
                height: 25vh;
                font-size: 18px;
                text-align: justify;
                display: flex;
                align-items: center;
                padding: 30px;
            }
        }
    }
}

@media (max-width: 1400px) {
    .main-sec {
        padding: 2rem;

        .headerSec {
            flex-direction: column;

            .left {
                width: 100%;
            }

            .right {
                font-size: 18px;
            }
        }

    }

    .main-sec-TM {
        padding: 2rem;
        .headerSec {
            flex-direction: column;

            .left {
                width: 100%;
            }

            .right {
                font-size: 18px;
                margin-left: 0px !important;
            }
        }

    }
}

@media (max-width: 1420px) {
    .main-sec {
        .condent-sec {
            flex-wrap: wrap;
            justify-content: space-around;
            margin: auto;

            .mission {
                margin: 0 40px;
            }
        }
    }
}

@media (max-width: 760px) {
    .main-sec {
        .headerSec {
            flex-direction: column;
            align-items: flex-start;

            .left {
                width: 100%;
            }

            .right {
                font-size: 16px;
                text-align: justify;
            }
        }

        .condent-sec {
            .mission {
                width: 350px;
                margin: auto;
                position: relative;
                right: 0;
                left: 0;

                .img {
                    position: relative;
                    top: 45px;
                    right: 20px;
                }

                .note {
                    height: auto;
                    font-size: 14px;
                }

                .note2 {
                    z-index: 9;
                }
            }
        }
    }

    .main-sec-TM {
        .headerSec {
            flex-direction: column;
            align-items: flex-start;

            .right {
                font-size: 16px;
                text-align: justify;
            }
        }
    }
}

@media (max-width: 420px) {

    .main-sec {
        .condent-sec {


            .mission {
                width: auto;
                height: auto;
                position: relative;
                left: 0;
                right: 0;
                margin: unset;

                .head {
                    position: relative;
                    top: 58px;
                    font-size: 16px;
                    padding: 0 50px;
                }

                .img {
                    .imgSec {
                        width: 60px;
                        position: relative;
                        top: -10px;
                    }
                }

                .note {
                    padding: 20px;
                }

            }
        }
    }

}