.lt-testimonials {
    margin: 3rem;
}

.lt-title {
    border-bottom: 10px solid #3B8A6E;
}

.lt-main-title {
    color: #052941;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.lt-s-title {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 126%; /* 20.16px */
    margin-left: 20px;
}

.lt-s-subtitle {
    color: rgba(0, 0, 0, 0.50);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%; /* 20.16px */
    margin-left: 20px;
}

.lt-reviews {
    margin: 20px 0px !important;    
}

.lt-review {
    height: 325px;
    padding: 2rem;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 3px 5px 14px 0px rgba(0, 0, 0, 0.10), 13px 22px 26px 0px rgba(0, 0, 0, 0.09), 29px 49px 34px 0px rgba(0, 0, 0, 0.05), 52px 88px 41px 0px rgba(0, 0, 0, 0.01), 81px 137px 45px 0px rgba(0, 0, 0, 0.00);
}

.lt-review-title {
    color: #052941;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 15px;
}

.lt-review-desc {
    color: #052941;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.r-ratings {
    margin-top: 10px;
}

.testimonial-rating {
    .p-rating-icon {
      color: gold !important;
    }
}

@media (max-width: 800px) {
   
}