.status-card-container .p-card .p-card-body {
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 10px;
}

.status-card-container .p-card .p-card-content {
    padding: 8px !important;
}

.status-card-heading{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.status-cards{
    padding-top: 10px;
}

.status-card{
    border-radius: 10px;
}

.g-line {
    font-size: 70px;
    border-left: 4px solid;
}

.number {
    color: #000000;
    font-size: 32px;
    margin: 12px 0px 4px 0px;
}

.sub-text{
    padding-top: 2px;
}

.role-desc {
    font-size: 16px;
}

@media only screen and (max-width: 1700px) {

    .number {
        font-size: 22px;
    }

    .textvalue {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1400px) {
    .status-card-heading {
        font-size: 20px;
    }

    .status-card-container {
        .status-card-icon {
            width: 30px !important;
        }
    }

    .col-1 {
        padding: 0rem !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

}

@media only screen and (max-width: 1200px) {
    .col-1 {
        padding: 0rem !important;
    }

    .status-card {
        .col-3 {
            padding: 0rem !important;
        }
    }

}


@media only screen and (max-width: 1008px) {
    .status-card .col-8 {
        margin-top: -100px;
        display: grid;
        padding: 10px 30px;
    }

    .status-card .col-3 {
        margin-top: -100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100% !important;
    }

}

@media only screen and (max-width: 600px) {
    .status-card .col-8 {
        margin-top: -100px;
        display: grid;
        padding: 10px 30px;
    }

    .status-card .col-3 {
        margin-top: -100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100% !important;
    }

}