.Showcase {
    background-color: #052941;
    margin-top: 20px;
    margin-bottom: 20px;
}

.showcase-title {
    color: #FFF;
    font-family: Poppins;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding: 40px 20px;
}

.showcase-items {
    padding-bottom: 80px;
}

.item-number {
    color: #FFF;
    font-family: Poppins;
    font-size: 85px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

.item-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.sc-item1 {
    padding: 40px !important;
    background-image: url(../../../Assets/Home/Showcase/business-time.svg);;
    background-repeat: no-repeat;
    background-position: center
}

.sc-item2 {
    padding: 40px !important;
    background-image: url(../../../Assets/Home/Showcase/school.svg);;
    background-repeat: no-repeat;
    background-position: center
}

.sc-item3 {
    padding: 40px !important;
    background-image: url(../../../Assets/Home/Showcase/graduation-cap.svg);;
    background-repeat: no-repeat;
    background-position: center
}

.sc-item4 {
    padding: 40px !important;
    background-image: url(../../../Assets/Home/Showcase/laptop.svg);;
    background-repeat: no-repeat;
    background-position: center
}

@media (max-width: 1200px) {
    .showcase-title {
        font-size: 40px;
    }
    .item-number {
        font-size: 65px;
    }
    .item-text{
        font-size: 25px;
    }
}

@media (max-width: 490px) {
    .showcase-title {
        font-size: 30px;
    }
    .item-number {
        font-size: 45px;
    }
    .item-text{
        font-size: 20px;
    }
    .sc-item1 {
        padding: 60px !important;

    }
    .sc-item2 {
        padding: 60px !important;

    }
    .sc-item3 {
        padding: 60px !important;

    }
    .sc-item4 {
        padding: 60px !important;

    }
}