.personal-section {
    .head {
        font-family: Poppins;
        font-size: 24px;
    }

    .para-text {
        font-family: Poppins;
        font-size: 20px;
    }

    .descrip {
        font-family: Poppins;
        font-size: 20px;
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);

    }

    .auto-skills {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
 
        .p-inputtext  {
            width: 100% !important;
        }
    }

    .statement-section {
        .head {
            font-family: Poppins;
            font-size: 24px;
        }

        .para-text {
            font-family: Poppins;
            font-size: 20px;
        }

        .descrip {
            font-family: Poppins;
            font-size: 20px;
            font-style: italic;
            color: rgba(0, 0, 0, 0.5);

        }
    }

    .auto-skills label {
        font-size: 18px !important;
        color: white !important;
    }
}

.input-container {
    display: flex;
    align-items: center;
}

.input-field {
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 10px;
}


.chip {
    border-radius: 30px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    padding: 7px 15px;
    background-color: #052941;
    color: white;
    margin: 0.5rem !important
}

// .chip span {
//     margin-right: 5px;
// }

.chip button {
    background: none;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 22px;
}

.mobile-personal{
    display: none;
}

@media screen and (max-width:1400px) {
    .personal-section {
        .head {
            font-size: 20px;
        }

        .para-text {
            font-size: 18px;
        }

        .descrip {
            font-size: 18px;

        }

        .custom-placeholder::placeholder {
            font-size: 14px;
        }
        
    
        .statement-section {
            .head {
                font-size: 20px;
            }

            .para-text {
                font-size: 18px;
            }

            .descrip {
                font-size: 16px;
    
            }
        }
    }
}

@media screen and (max-width:1000px) {
    .personal-section {
        .auto-skills {
            justify-content: flex-end !important;
            align-items: flex-end !important;

            .skills {
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width:600px) {
    .chip{
        font-size: 18px !important;
    }
}

@media screen and (max-width:767px) {
    .desktop-personal{
       display: none;
    }
    .mobile-personal{
        display: block;
    }
}