.laulama-header .logo{
  img {
    width: 100%;
    height: 100%;
}
  a {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
  }
}
 

.menu-toggle {
  cursor: pointer;
  display: none;
}

.menu-toggle.active .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-6px, 6px);
}

.menu-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active .bar:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}



.laulama-header {
  padding: 2rem !important;
  width: 100%;
  height: auto;
  // box-shadow: 0 6px 8px rgba(0, 0, 0, .10196078431372549);
  background: hsla(0, 0%, 100%, .7803921568627451);
  // position: fixed;
  // top: 0;
  z-index: 999;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  min-height: 111px;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;

    li {
      margin: 0 20px;
      text-decoration: none;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #000000;
      }
    }
  }

  .menu-toggle {
    cursor: pointer;
  }
}


@media (max-width: 900px) {
  .laulama-header {
    ul {
      li {
        margin: 0 15px;
      }
    }
  }
}


@media (max-width: 768px) {

  
  .menu-toggle {
    display: block;
  }

  .menu {
    display: none;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 100px;
    // background: rgba(232, 235, 238, .9686274509803922);
    background: #052941;
    left: 0;
    text-align: center;
    overflow-y: auto;
  }

  .menu.show-menu {
    display: block;
    border-top: 2px solid white;
  }

  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .menu li {
    margin-bottom: 0.5rem;
  }

  .menu a {
    color: #fff !important;
    text-decoration: none;
  }

  .laulama-header {
    nav ul {
      display: grid;
      align-items: center;
      justify-content: center;
      padding: 10rem 20px;

      li {
        padding: 10px;
      }
    }
  }
}

.active-menu-item {
  font-weight: bold;
}
